body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

button {
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  border: none;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}

svg:focus {
  outline: none;
}

textarea:focus-visible,
input:focus-visible {
  outline-color: #2684ff;
}

h1 {
  color: #2f2f2f;
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin: 0 0 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

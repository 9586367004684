.forgot-password {
  color: #2f2f2f;

  &__logo {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    img {
      min-height: 40px;
    }
  }

  &__content-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__content {
    align-items: flex-start;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 10%;
    width: 100%;
  }

  &__header {
    font-size: 48px;
    font-weight: bold;
    line-height: 65px;
    margin-bottom: 40px;
  }

  &__details {
    margin-bottom: 32px;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;

    label {
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 8px;
    }
  }

  &__input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 32px;
    padding: 2px 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 12%;
    width: 100%;
  }

  &__link {
    color: #176998 !important;
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
    text-decoration: none;
  }

  &__button {
    margin-top: 16px;
    width: 50%;
  }
}

@media (min-width: 960px) {
  .forgot-password {
    background-image: url('../../media/banner-bg-home2.jpg');
    height: 100vh;
    width: 100vw;

    &__content-wrapper {
      width: 50%;
    }
  }
}

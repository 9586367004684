.header {
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 39px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 18px 32px;
  position: relative;

  &__logo {
    height: 27px;
  }

  &__right-actions {
    align-items: center;
    display: flex;
    justify-content: center;   
  }

  &__btn {
    margin-right: 24px;
  }
}

.header-wrapper {
  background-color: #fff;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  z-index: 9;
}

.button {
  background: #176998;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 18px;

  &:hover {
    background: #1e88b7;
  }

  &:active {
    background: #25a8d6;
  }

  &:disabled, {
    background: none;
    cursor: not-allowed;
  }

  &--gray {
    background: #e5e5e5;
    color: #2f2f2f;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
  }
}

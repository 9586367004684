.new-request-modal {
  &__form {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
    margin-bottom: 48px;
  }

  .form-input {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__char-count {
    bottom: 0;
    font-size: 12px;
    margin-bottom: -25px;
    position: relative;
    right: 10px;
    text-align: end;
    top: -25px;
  }

  &__input {
    &--small,
    &--large {
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      padding: 2px 8px;
      width: calc(100% - 18px);

      &:focus {
        border-color: #268455;
      }

      &::placeholder {
        color: hsl(0, 0%, 50%);
        font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
      }
    }

    &--small {
      min-height: 32px;
    }

    &--large {
      font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 19px;
      min-height: 156px;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }

  .form-select {
    &-error__control {
      border-color: #c61010;
    }
  }

  .error {
    border-color: #c61010;
  }

  &__decoding {
    margin-top: 5px;
  }
}

.modal {
  &__background {
    .new-request-modal {
      left: 20%;
      width: 60%;
    }
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.Toastify {
  .toast {
    &__body {
      color: #2f2f2f;
      font-size: 14px;
      font-weight: normal;
      line-height: 19px;
      padding: 10px;
    }
  }
}

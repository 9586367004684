.form-input {
  display: flex;
  margin-bottom: 24px;

  &__key {
    font-weight: 500;
    margin-right: 32px;
    margin-top: 9.5px;
    width: 149px;
  }
  
  &__value {
    width: 70%;
  }

  &__error {
    align-items: center;
    color: #c61010;
    display: flex;
    font-size: 12px;
    line-height: 12px;
    margin-top: 8px;
  }

  &__error-message {
    margin-left: 10px;
  }
}

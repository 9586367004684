.icon-button {
  background: none;
  border-radius: 30px;
  line-height: 0;
  padding: 10px;

  &--primary {
    &:hover {
      background: #1769981c;
    }

    &:active {
      background: #1769983b;
    }
  }

  &--default {
    &:hover {
      background: #80808021;
    }

    &:active {
      background: #80808045;
    }
  }

  &:disabled {
    background: none;
  }
}

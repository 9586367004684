.status {
  border-radius: 4px;
  color: #2f2f2f;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  padding: 3px 10px;
  text-align: center;
  width: fit-content;

  &--translating {
    background: #fdf2b8;
  }

  &--downloaded {
    background: #e0dede;
  }

  &--complete {
    background: #c7efae;
    font-weight: bold;
  }

  &--waiting {
    background: #d5e4ed;
  }
}

.delete-button {
  visibility: hidden;
}

.table {
  &__row:hover {
    .delete-button {
      cursor: pointer;
      visibility: visible;
    }
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  button {
    margin-top: 16px;
    width: fit-content;
  }
}

.current-requests {
  margin-bottom: 42px;
}

.past-requests {
  margin-top: 42px;
}

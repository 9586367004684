.cancellation-modal {
  &__text {
    color: #2f2f2f;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
  }

  &__details {
    margin-bottom: 32px;
    margin-top: 16px;
  }

  &__key-value {
    display: flex;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__key {
    display: flex;
    font-weight: 500;
    justify-content: flex-end;
    margin-right: 24px;
    width: 147px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}

.modal {
  &__background {
    .cancel-request-modal {
      left: 31%;
      width: 38%;
    }
  }
}

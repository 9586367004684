.pagination-controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &__count {
    color: #838282;
    font-size: 14px;
    font-weight: normal;
    line-height: 19px;
    margin-right: 18px;
  }

  &__back,
  &__front {
    border-radius: 100px;
    padding: 8px 10px;
    
    &:hover {
      background: #f3f3f3;
    }

    &:active {
      background: #e1e1e1;
    }

    &:disabled {
      background: none;
    }
  }

  &__back {
    margin-right: 8px;
  }
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  left: 15%;
  padding: 24px;
  position: fixed;
  top: 15%;
  transition: all 0.3s ease-out;
  width: 70%;
  z-index: 500;

  &__background {
    background: rgba(0, 0, 0, 0.4);
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 100;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h1 {
      margin-bottom: 0;
    }
  }
}

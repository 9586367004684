.user-controls {
  background: #e1e1e1;
  border-radius: 100px;
  color: #2f2f2f;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  padding: 8px 10px;
}

.options {
  display: flex;
  flex-direction: column;

  &__btn {
    align-items: center;
    background-color: #fff;
    color: #2f2f2f;
    display: flex;
    font-size: 16px;
    justify-content: center;
    padding: 12px 24px;

    &:last-of-type {
      border-top: 1px #e5e5e5 solid;
    }

    .icon {
      margin-left: 16px;
    }
  }
}

.request-list {
  &__header {
    align-items: center;
    display: flex;
  }

  &__title {
    margin: 0 16px 0 0;
  }
}

$page-padding: 32px;

.page-layout {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $page-padding+75px $page-padding $page-padding $page-padding;
  }
}

$border-color: #e5e5e5;

.table {
  border-spacing: 0;
  width: 100%;

  &__col-header,
  &__cell {
    border-bottom: 1px solid $border-color;

    &:first-child {
      border-left: 1px solid $border-color;
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }

  &__col-header {
    border-top: 1px solid $border-color;
    color: #2f2f2f;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding: 10px 0 10px 15px;
    text-align: left;
  } 

  &__cell {
    color: #2f2f2f;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    padding: 10px 0 10px 15px;
    position: relative;

    &:last-child {
      border-right: 1px solid $border-color;
      width: 30px;
    }

    &--complete {
      font-weight: bold;

      &:first-child {
        border-left: 5px solid #176998;
      }
    }

    &--downloaded {
      background: rgba(218, 218, 218, 0.46);
    }
    
  }

  &__row {
    position: relative;

    &:hover {
      background-color: rgba(218, 218, 218, 0.46);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
      z-index: 2;
    }

    &--loading:hover {
      background: none;
      box-shadow: none;
    }

    &--complete {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    }
  }
}
